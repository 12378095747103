@import (reference) "./vars.less";

.background {}
each(@swatches, {
	.background--@{key} {
		--backgroundColor : @value;
		background-color: @value;
	}
});

.color {}
each(@swatches, {
	.color--@{key} {
		--color : @value;
		color: @value;
	}
});
