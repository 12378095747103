@font-face {
	font-family: 'ABCGrow-BD';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		 url('./ABCGrow-BD.woff2') format('woff2'),
		 url('./ABCGrow-BD.woff') format('woff');
}

@font-face {
	font-family: 'ABCGrow-D';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		 url('./ABCGrow-D.woff2') format('woff2'),
		 url('./ABCGrow-D.woff') format('woff');
}
